.imageWrapper {
  display: block;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  box-sizing: border-box;
  margin: 0px
}
